import React from 'react';
import {Button} from '../core-ui';
import {Tooltip} from '@material-ui/core';
import {StyleSheet} from 'react-native';
import {BLACK} from '../constants/colors';

export type ResetFilterButtonProps = {
  onPress: () => void;
};

export function ResetFilterButton({onPress}: ResetFilterButtonProps) {
  return (
    <Tooltip title="Reset Filter">
      <Button
        inverted
        color="primary"
        icon="refresh"
        onPress={onPress}
        style={styles.filterButton}
      />
    </Tooltip>
  );
}

const styles = StyleSheet.create({
  filterButton: {
    backgroundColor: 'transparent',
    borderWidth: 0,
    color: BLACK,
  },
});
